import React from 'react';
import Modal from './Modal';
import PropTypes from 'prop-types';
import { IntlProvider, useIntl } from 'react-intl';
import { translations } from './i18n';
import {useForm} from "react-hook-form";

const ConfirmForm = (props) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: {...props.formData} });

  const setData = (field, data) => {
    let copyFormData = {
      ...props.formData,
      [field]: data
    };
    props.setFormData(copyFormData);
  };

  return (
    <>
      {props.text}
      <br />
      <br />
      { props.formData !== undefined &&
        <form>
          {(props.formFields.map((field, index) => {
              if(field.fieldType == 'select') {
                return(<div className="form-group required" key={index}>
                  <label>{intl.formatMessage({ id: field.label })}</label>
                  <select
                    key={index}
                    autoFocus
                    className="form-control" {...register(field.fieldName, { required: true })}
                    onChange={(e) => setData(field.fieldName, e.target.value)}
                  >
                    <option key="0" value="">
                      {intl.formatMessage({ id: 'shared.dropdown.please-select' })}
                    </option>
                    {field.options.map((option, index) => {
                      return(
                        <option key={index + 1} value={option.value}>
                          {intl.formatMessage({ id: option.label })}
                        </option>
                      );
                    })}
                  </select>
                  {errors[field.fieldName]?.type === 'required' && (
                    <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                  )}
                </div>);
              } else if (field.fieldType == 'input') {
                return(
                  <div className="form-group required" key={index}>
                    <label>{intl.formatMessage({ id: field.label })}</label>
                    <input
                      key={index}
                      className="form-control" {...register(field.fieldName, { required: true })}
                      onChange={(e) => setData(field.fieldName, e.target.value)}>
                    </input>
                    {errors[field.fieldName]?.type === 'required' && (
                      <div className="field-error">{intl.formatMessage({ id: 'shared.forms.required' })}</div>
                    )}
                  </div>
                );
              }
            })
          )}
          <div className="flex-right">
            <button type='button' className="btn btn-primary small angled-top-left" onClick={handleSubmit(props.onConfirm)}>
              <i className="fas fa-check"></i>&nbsp;
              {intl.formatMessage({ id: 'enrolments.questions.proceed' })}
            </button>

            <button type='button' className="btn btn-secondary small angled-bottom-right" onClick={props.onCancel}>
              <i className="fas fa-times"></i>&nbsp;
              {intl.formatMessage({ id: 'shared.cancel' })}
            </button>
          </div>
        </form>
      }
      {
        props.formData === undefined &&
        <div className="flex-right">
          <button type={props.noRefresh ? 'button' : 'submit'} className="btn btn-primary small angled-top-left" onClick={props.onConfirm}>
            <i className="fas fa-check"></i>&nbsp;
            {props.formData !== undefined ? intl.formatMessage({ id: 'enrolments.questions.proceed' }) : intl.formatMessage({ id: 'shared.yes' })}
          </button>

          <button type={props.noRefresh ? 'button' : 'submit'} className="btn btn-secondary small angled-bottom-right" onClick={props.onCancel}>
            <i className="fas fa-times"></i>&nbsp;
            {props.formData !== undefined ? intl.formatMessage({ id: 'shared.cancel' }) : intl.formatMessage({ id: 'shared.no' })}
          </button>
        </div>
      }
    </>
  );
};

ConfirmForm.propTypes = {
  text: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  noRefresh: PropTypes.bool,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string.isRequired,
      fieldType: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }).isRequired,
  )
};

const ConfirmModal = (props) => {
  const modalContent = () => {
    return <ConfirmForm
      text={props.text}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
      noRefresh={props.noRefresh}
      formData={props.formData}
      setFormData={props.setFormData}
      formFields={props.formFields} />;
  };

  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <Modal
        open={true}
        onClose={props.onCancel}
        title={props.title}
        content={modalContent()}
        noTitleTranslation
      ></Modal>
    </IntlProvider>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  noRefresh: PropTypes.bool,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string.isRequired,
      fieldType: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }).isRequired,
  )
};

export default ConfirmModal;
