// https://derk-jan.com/2020/10/rails-ujs-custom-confirm/

import Rails from "@rails/ujs";
import React from "react";
import * as ReactDOM from "react-dom";
import ConfirmModal from "./ConfirmModal";
const nativeConfirm = Rails.confirm;

let __SkipConfirmation = false;

function showDialog(confirmTitle, confirmText, onConfirm) {
  const dialogDiv = document.createElement("div");
  dialogDiv.setAttribute("id", "__dialogDiv");
  document.body.appendChild(dialogDiv);

  const onCancel = () => {
    ReactDOM.unmountComponentAtNode(dialogDiv);
    document.body.removeChild(dialogDiv);
  };

  const onClose = () => {
    onConfirm();
    onCancel();
  };

  ReactDOM.render(
    <ConfirmModal title={confirmTitle} text={confirmText} onConfirm={onClose} onCancel={onCancel}></ConfirmModal>,
    dialogDiv
  );
}

Rails.confirm = function (message, element) {
  if (__SkipConfirmation) {
    return true;
  }

  const confirmTitle = element.getAttribute("data-confirm-title");
  const confirmText = element.getAttribute("data-confirm");
  if (!confirmTitle) {
    return nativeConfirm(message, element);
  }

  function onConfirm() {
    __SkipConfirmation = true;
    element.click();
    __SkipConfirmation = false;
  }

  showDialog(confirmTitle, confirmText, onConfirm);

  return false;
};
