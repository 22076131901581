import en from "./en.json";
import de from "./de.json";
import es from "./es.json";
import fr from "./fr.json";
import ko from "./ko.json";
import ar from "./ar.json";
import zh_CN from "./zh-CN.json";
import ms from "./ms.json";
import hi from "./hi.json";

const translations = {
  en,
  de,
  es,
  fr,
  ko,
  ar,
  zh_CN,
  ms,
  hi,
};

translations['zh-CN'] = translations['zh_CN'];
delete translations['zh_CN'];

const locale = "en";

export { translations, locale };
