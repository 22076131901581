import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { createContext } from 'react';

export const ModalContext = createContext({ closeModal: function () {} });

const Modal = (props) => {
  const intl = useIntl();

  const handleClose = (e) => {
    props.onClose(e);
  };

  const content = () => {
    if (props.component) {
      const Component = props.component;
      return <Component {...props.params} handleClose={handleClose}></Component>;
    } else {
      return props.content;
    }
  };

  return (
    <div>
      <div
        className="modal-bg"
        onClick={(e) => {
          if (props.modalVariation != 'confirm-modal') {
            props.onClose(e);
          }
        }}
      ></div>
      <div className={`modal-container ${props.modalVariation}`}>
        <div className="title-block">
          <div className="inner-title">
            <h2>{props.noTitleTranslation ? props.title : intl.formatMessage({ id: props.title })}</h2>
            <i
              onClick={(e) => {
                handleClose(e);
              }}
            >
              <span className="fas fa-times"></span>
            </i>
          </div>
        </div>
        <div className={`modal-content ${props.classNameForContent}`}>
          <ModalContext.Provider value={{ closeModal: handleClose }}>{content()}</ModalContext.Provider>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.object,
  component: PropTypes.object,
  params: PropTypes.object,
  title: PropTypes.string,
  noTitleTranslation: PropTypes.bool,
  classNameForContent: PropTypes.string,
  modalVariation: PropTypes.oneOf(['wide', 'image-magnifier', 'confirm-modal']),
};

export default Modal;
