// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

window.FontAwesomeConfig = {};
window.FontAwesomeConfig.autoAddCss = false;
require('@fortawesome/fontawesome-pro/js/all');

Rails.start();
ActiveStorage.start();

import toastr from 'toastr';
window.toastr = toastr;

import jstz from 'jstimezonedetect';
window.jstz = jstz;

import Sortable from 'sortablejs';
window.sortable = Sortable;

import axios from '../bundles/main/components/util/axios';
window.axios = axios;

require('../bundles/main/components/util/custom_confirm');

import tippy from 'tippy.js';
window.tippy = tippy;

window.EMAIL_REGEX = new RegExp(/^[A-Za-z0-9_!#$%&'*+=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');

document.addEventListener('DOMContentLoaded', () => {
  const exportTableAsPdf = () => {
    let url = window.location.href;
    if (url.includes('?')) {
      url = url + '&format=pdf';
    } else {
      url = url + '?format=pdf';
    }
    window.open(url, '_blank');
  };

  const el = document.getElementById('exportTableAsPdf');
  if (el) {
    el.addEventListener('click', exportTableAsPdf);
  }
});

import '../../assets/stylesheets/application.scss';
